import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Inicio from '../paginas/Inicio.vue';
import Calendario from '../componentes/inicio/Calendario.vue';
import GuiaPasos from '../componentes/inicio/GuiaPasos.vue';
import Contacto from '../componentes/inicio/Contacto.vue';
import PreguntasFrecuentes from '../componentes/inicio/PreguntasFrecuentes.vue';

import BienvenidaNuevos from '../paginas/1_BienvenidaNuevos.vue';
import PerfilEstudiante from '../paginas/2_PerfilEstudiante.vue';
import PagoMatricula from '../paginas/3_PagoMatricula.vue';
import PagoArancel from '../paginas/4_PagoArancel.vue';
import Resumen from '../paginas/5_Resumen.vue';

import LoginAntiguos from '../paginas/LoginAntiguos.vue';
import InhabilitantesAntiguos from '../paginas/1_InhabilitantesAntiguos.vue';

import EduContinuaInicio from '../paginas/InicioEduContinua.vue';
import EduContinuaCalendario from '../componentes/inicio/EduContinuaCalendario.vue';
import EduContinuaBienvenida from '../paginas/1_BienvenidaEduContinua.vue';

import EstadoPagoWebpay from '../paginas/EstadoPagoWebpay.vue';
import ValidarCert from '../paginas/ValidarCert.vue';
import ContratoAntiguos from '../paginas/ContratoAntiguos.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Inicio,

    children: [
      {
        path: '',
        name: 'inicio',
        component: Calendario,
      },
      {
        path: 'guia-paso-a-paso',
        name: 'guia pasos',
        component: GuiaPasos,
      },
      {
        path: 'contacto',
        name: 'contacto',
        component: Contacto,
      },
      {
        path: 'preguntas-frecuentes',
        name: 'preguntas frecuentes',
        component: PreguntasFrecuentes,
      },
    ],
  },
  {
    path: '/bienvenida',
    name: 'bienvenida',
    component: BienvenidaNuevos,
  },
  {
    path: '/contrato-antiguos',
    name: 'contrato antiguos',
    component: ContratoAntiguos,
  },
  {
    path: '/perfil-estudiante',
    name: 'perfil estudiante',
    component: PerfilEstudiante,
  },
  {
    path: '/pago-matricula',
    name: 'pago matricula',
    component: PagoMatricula,
  },
  {
    path: '/estado-pago-matricula',
    name: 'estado pago matricula',
    component: EstadoPagoWebpay,
  },
  {
    path: '/pago-arancel',
    name: 'pago arancel',
    component: PagoArancel,
  },
  {
    path: '/estado-pago-arancel',
    name: 'estado pago arancel',
    component: EstadoPagoWebpay,
  },
  {
    path: '/resumen',
    name: 'resumen',
    component: Resumen,
  },
  {
    path: '/login-antiguos',
    name: 'login antiguos',
    component: LoginAntiguos,
  },
  {
    path: '/inhabilitantes-antiguos',
    name: 'inhabilitantes antiguos',
    component: InhabilitantesAntiguos,
  },
  {
    path: '/educacion-continua/',
    component: EduContinuaInicio,

    children: [
      {
        path: '',
        name: 'educontinua inicio',
        component: EduContinuaCalendario,
      },
      {
        path: 'guia-paso-a-paso',
        name: 'educontinua guia pasos',
        component: GuiaPasos,
      },
      {
        path: 'contacto',
        name: 'educontinua contacto',
        component: Contacto,
      },
    ],
  },
  {
    path: '/educacion-continua/bienvenida',
    name: 'educontinua bienvenida',
    component: EduContinuaBienvenida,
  },
  {
    path: '/validar/:folio(\\d+)/:llave/',
    name: 'validar certificado',
    component: ValidarCert,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
