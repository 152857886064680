
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route, NavigationGuardNext } from 'vue-router';

import store from '@/store';

import FondoDiagonal from '@/componentes/UI/FondoDiagonal.vue';
import ProgresoPasos from '@/componentes/ProgresoPasos.vue';

import { DatosContactoI, getDatosContacto, patchDatosContacto } from '@/assets/api/matricula';
import {
  RegionI, ComunaI, getRegiones, PaisI, getPaises,
} from '@/assets/api/general';

@Component({ components: { FondoDiagonal, ProgresoPasos } })
export default class PerfilEstudiante extends Vue {
  datosContacto: DatosContactoI = {} as DatosContactoI;

  regiones: RegionI[] = [];

  region: RegionI | null = null;

  comuna: ComunaI | null = null;

  paises: PaisI[] = [];

  nacionalidad: PaisI | null = null;

  confirmarCorreo = '';

  generos = ['masculino', 'femenino', 'otro']

  otroGenero = '';

  cargando = false;

  error = '';

  check_nombre_social = false;

  isEnabled = true;

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<PerfilEstudiante>) {
    // evitar acceso no autenticado
    if (!store.state.sesion) {
      next({ name: store.getters.linkInicio });
      return;
    }

    // redirigir en caso de estar en otra etapa (permitir volver desde resumen)
    if (store.getters.etapaEstudiante === 0) {
      next({ name: store.getters.linkBienvenida });
      return;
    }
    if (store.getters.etapaEstudiante === 2) {
      next({ name: store.getters.linkPagoMatricula });
      return;
    }
    if (store.getters.etapaEstudiante === 3) {
      next({ name: store.getters.linkPagoArancel });
      return;
    }

    const promesaRegiones = getRegiones();
    const promesaPaises = getPaises();
    const promesadatos = getDatosContacto(store.getters.idEstudiante);

    Promise.all([promesadatos, promesaRegiones, promesaPaises])
      .then(([datosContacto, regiones, paises]) => {
        next((vm) => {
          /* eslint-disable no-param-reassign */
          vm.datosContacto = datosContacto;
          vm.regiones = regiones;
          vm.paises = paises;

          regiones.forEach((region) => {
            region.comunas.forEach((comuna) => {
              if (comuna.id === datosContacto.direccion_comuna) {
                vm.comuna = comuna;
                vm.region = region;
              }
            });
          });

          paises.forEach((nacionalidad) => {
            if (nacionalidad.id === datosContacto.nacionalidad) {
              vm.nacionalidad = nacionalidad;
            }
          });

          // detectar otros generos
          if (datosContacto.genero !== '' && !vm.generos.includes(datosContacto.genero)) {
            vm.otroGenero = datosContacto.genero;
            vm.datosContacto.genero = 'otro';
          }

          // rellenar mail si se están modificando los datos
          if (store.getters.etapaEstudiante > 3) {
            vm.confirmarCorreo = datosContacto.mail_secundario;
          }
          /* eslint-enable no-param-reassign */
        });
      });
  }

  @Watch('check_nombre_social')
  validarCheck() {
    this.isEnabled = !this.check_nombre_social;
  }

  @Watch('comuna')
  validarComuna() {
    const input = (this.$refs.comuna as Vue).$refs.search as HTMLFormElement;

    if (!this.comuna) {
      input.setCustomValidity('Por favor ingresa la comuna de tu domicilio');
      input.reportValidity();
      return false;
    }
    input.setCustomValidity('');
    return true;
  }

  @Watch('nacionalidad')
  validarNacionalidad() {
    const input = (this.$refs.nacionalidad as Vue).$refs.search as HTMLFormElement;

    if (!this.nacionalidad) {
      input.setCustomValidity('Por favor ingresa tu nacionalidad');
      input.reportValidity();
      return false;
    }
    input.setCustomValidity('');
    return true;
  }

  @Watch('datosContacto.mail_secundario')
  @Watch('confirmarCorreo')
  validarConfirmarCorreo() {
    const input = this.$refs.confirmarCorreo as HTMLFormElement;

    if (!this.confirmarCorreo) {
      input.setCustomValidity('Por favor confirma tu correo');
      input.reportValidity();
      return false;
    }
    if (this.confirmarCorreo !== this.datosContacto.mail_secundario) {
      input.setCustomValidity('Los correos no calzan, verifica que lo ingresaste correctamente');
      input.reportValidity();
      return false;
    }
    input.setCustomValidity('');
    return true;
  }

  @Watch('datosContacto.emergencia_telefono_celular')
  @Watch('datosContacto.emergencia_telefono_laboral')
  @Watch('datosContacto.emergencia_telefono_fijo')
  validarEmergenciaTelefono() {
    const input = this.$refs.emergencia_telefono_celular as HTMLFormElement;

    if (!(this.datosContacto.emergencia_telefono_celular
      || this.datosContacto.emergencia_telefono_laboral
      || this.datosContacto.emergencia_telefono_fijo)) {
      input.setCustomValidity(
        'Por favor ingresa por lo menos un telefono de tu contacto de emergencia',
      );
      input.reportValidity();
      return false;
    }
    input.setCustomValidity('');
    return true;
  }

  enviar() {
    // validar datos
    if (!(this.validarComuna() && this.validarConfirmarCorreo()
      && this.validarEmergenciaTelefono() && this.validarNacionalidad())) {
      return;
    }

    if (this.comuna) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.datosContacto.direccion_comuna = this.comuna.id;
    }
    if (this.nacionalidad) {
      this.datosContacto.nacionalidad = this.nacionalidad.id;
    }

    if (this.datosContacto.genero === 'otro') {
      this.datosContacto.genero = this.otroGenero;
    }

    if (store.getters.idEstudiante) {
      this.cargando = true;
      patchDatosContacto(store.getters.idEstudiante, this.datosContacto)
        .then((respuesta) => {
          store.commit('actualizarNombre', respuesta.nombre_social);
          store.commit('actualizarEtapa', 2);

          // si ya se llegó al resumen retornar a ese paso
          if (store.getters.etapaEstudiante === 4) {
            this.$router.push({ name: 'resumen' });
          } else {
            this.$router.push({ name: store.getters.linkPagoMatricula });
          }
        }).catch(() => {
          this.cargando = false;
          this.error = 'Lo sentimos, ha ocurrido un error en el sistema. Por favor intenta nuevamente y si el problema persiste ponte en contacto con el encargado de matrícula';
        });
    }
  }
}
