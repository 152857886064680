import api from '@/assets/api';

const root = 'pagos';

interface PagoMatriculaI {
  estudiante_id: number;
  forma_pago_id: number;
}

interface RespuestaPagoI {
  estado: string;
}

interface RespuestaWebpayI {
  url_redirect: string;
  token_webpay: string;
}

function postPagoMatricula(data: PagoMatriculaI):
  Promise<RespuestaPagoI | RespuestaWebpayI> {
  return new Promise((resolve, reject) => {
    api.post(`${root}/pago-matricula/`, data)
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

interface PagoArancelI {
  estudiante_id: number;
  forma_pago_id: number;
}

function postPagoArancel(data: PagoArancelI): Promise<RespuestaPagoI | RespuestaWebpayI> {
  return new Promise((resolve, reject) => {
    api.post(`${root}/pago-arancel/`, data)
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

/* Redirige al usuario a la página de pago de webpay */
function redirectPostWebpay(url: string, token: string) {
  const form: HTMLFormElement = document.createElement('form');
  form.method = 'post';
  form.action = url;
  form.hidden = true;
  document.body.appendChild(form);

  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = 'token_ws';
  input.value = token;
  form.appendChild(input);

  form.submit();
}

interface ComprobanteI {
  authorization_code: string;
  buy_order: string;
  card_number: string;
  installments_amount: string;
  installments_number: string;
  monto: number;
  payment_type_code: string;
  productos: string[];
  tipo_cuota: string;
  transaction_date: string;
}

interface EstadoPagoI {
  estado: string;
  mensaje: string;
  comprobante_pago: ComprobanteI;
}

function getEstadoPagoMatricula(estudiante: number): Promise<EstadoPagoI> {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/camelcase
    api.get(`${root}/estado-pago-matricula/${estudiante}/`)
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

function getEstadoPagoArancel(estudiante: number): Promise<EstadoPagoI> {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/camelcase
    api.get(`${root}/estado-pago-arancel/${estudiante}/`)
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

export {
  postPagoMatricula, PagoArancelI, postPagoArancel, RespuestaPagoI,
  RespuestaWebpayI, redirectPostWebpay, EstadoPagoI, getEstadoPagoMatricula, getEstadoPagoArancel,
  ComprobanteI,
};
