
import { Component, Vue } from 'vue-property-decorator';
import { Route, NavigationGuardNext } from 'vue-router';

@Component
export default class GuiaPasos extends Vue {
  pregrado = true;

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<GuiaPasos>) {
    next((vm) => {
      if (to.name === 'educontinua guia pasos') {
        // eslint-disable-next-line no-param-reassign
        vm.pregrado = false;
      }
    });
  }

  get linkGuia() {
    if (this.pregrado) {
      return 'https://drive.google.com/file/d/1I2t19BsrBSDgVC5au9-wPB4vuHFsGfrL/preview';
    }
    return 'https://drive.google.com/file/d/1SbIVZ_AuGSegoMI4OeQ-cYqdpMOTbIbg/preview';
  }
}
