
import { Component, Vue } from 'vue-property-decorator';

const PropsVideo = Vue.extend({
  props: {
    url: {
      type: String,
      required: true,
    },
  },
});

@Component
export default class Video extends PropsVideo {}
