
import { Component, Vue } from 'vue-property-decorator';
import { Route, NavigationGuardNext } from 'vue-router';

@Component
export default class Contacto extends Vue {
  pregrado = true;

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<Contacto>) {
    next((vm) => {
      if (to.name === 'educontinua contacto') {
        // eslint-disable-next-line no-param-reassign
        vm.pregrado = false;
      }
    });
  }
}
