import api from '@/assets/api';

const root = 'general';

interface ComunaI {
  id: number;
  nombre: string;
  region: number;
}

interface RegionI {
  id: number;
  nombre: string;
  comunas: ComunaI[];
}

function getRegiones(): Promise<RegionI[]> {
  return new Promise((resolve, reject) => {
    api.get(`${root}/regiones`)
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

function getComunas(): Promise<ComunaI[]> {
  return new Promise((resolve, reject) => {
    api.get(`${root}/comunas`)
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

interface PaisI {
  id: number;
  nombre: string;
}

function getPaises(): Promise<PaisI[]> {
  return new Promise((resolve, reject) => {
    api.get(`${root}/paises`)
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

export {
  ComunaI, RegionI, getRegiones, getComunas, PaisI, getPaises,
};
