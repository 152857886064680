
import { Component, Vue } from 'vue-property-decorator';
import { Route, NavigationGuardNext } from 'vue-router';

import store from '@/store';

import FondoDiagonal from '@/componentes/UI/FondoDiagonal.vue';
import ProgresoPasos from '@/componentes/ProgresoPasos.vue';
import InformacionPago from '@/componentes/pago/InformacionPago.vue';
import VideoYoutube from '@/componentes/UI/VideoYoutube.vue';

import {
  ResumenI, getResumen, parseDetallesPeriodo, DetallesPeriodoI,
} from '@/assets/api/matricula';
import { ModalidadCuotaI, getModalidadesCuotas } from '@/assets/api/pagare';

@Component({
  components: {
    FondoDiagonal, ProgresoPasos, InformacionPago, VideoYoutube,
  },
})
export default class Resumen extends Vue {
  loading = true;

  resumen: ResumenI = {} as ResumenI;

  modalidadesCuotas: ModalidadCuotaI[] = [];

  detallesPeriodoMatricula: DetallesPeriodoI = {} as DetallesPeriodoI;

  detallesPeriodoArancel: DetallesPeriodoI = {} as DetallesPeriodoI;

  dia = (fecha: Date) => fecha?.toLocaleString('es-cl', { day: '2-digit' });

  mes = (fecha: Date) => fecha?.toLocaleString('es-cl', { month: 'long' });

  fecha(fecha: Date) {
    return `${this.dia(fecha)} de ${this.mes(fecha)}`;
  }

  videoCarrera: { [plan: number]: string } = {
    1: 'pSkloBeP0Gs', // enfermeria
    2: 'WO5D8-SaaYY', // obstetricia
    3: 'doRMMuCs090', // agronomia
    4: 'iJQwrUHJ6ao', // forestal
    5: 'u6gpHBjuwvI', // industrial
    6: 'DRtP8Th5_jw', // trabajo social
    7: '1EEx1fQbbBM', // psicologia
    8: '6Y27lzpOLmo', // informatica
  };

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<Resumen>) {
    // evitar acceso no autenticado
    if (!store.state.sesion) {
      next({ name: store.getters.linkInicio });
      return;
    }

    // redirigir en caso de estar en otra etapa
    if (store.getters.etapaEstudiante === 0) {
      next({ name: store.getters.linkBienvenida });
      return;
    }
    if (store.getters.etapaEstudiante === 1) {
      next({ name: 'perfil estudiante' });
      return;
    }
    if (store.getters.etapaEstudiante === 2) {
      next({ name: store.getters.linkPagoMatricula });
      return;
    }
    if (store.getters.etapaEstudiante === 3) {
      next({ name: store.getters.linkPagoArancel });
      return;
    }

    const promesaResumen = getResumen(store.getters.idEstudiante);
    const promesaModalidades = getModalidadesCuotas(
      store.getters.idPlan,
      store.getters.esEstudianteAntiguo ? 1 : 0,
    );

    Promise.all([promesaResumen, promesaModalidades])
      .then(([resumen, modalidades]) => {
        next((vm) => {
          /* eslint-disable no-param-reassign */
          vm.resumen = resumen;
          vm.modalidadesCuotas = modalidades;
          vm.detallesPeriodoMatricula = parseDetallesPeriodo(resumen.matricula.periodo_matricula);
          vm.detallesPeriodoArancel = parseDetallesPeriodo(resumen.arancel.periodo_matricula);
          vm.loading = false;
          /* eslint-enable no-param-reassign */
        });
      });
  }

  get video() {
    return this.videoCarrera[this.$store.getters.idPlan];
  }

  get puedeModificarPagos() {
    return this.resumen.hay_periodo_abierto && this.resumen.habilitado;
  }

  get estadoMatricula() {
    return this.resumen.matricula?.estado;
  }

  get estadoDisplayMatricula() {
    return this.resumen.matricula?.orden_compra?.estado_pago_display;
  }

  get montoMatricula() {
    return this.resumen.matricula?.monto_final;
  }

  get estadoArancel() {
    // pago contado
    if (!this.resumen.arancel.pago_cuotas && this.resumen.arancel.orden_compra) {
      return this.resumen.arancel.orden_compra.estado_pago;
    }

    // pago en cuotas - pagaré
    if (this.resumen.arancel.pagare) {
      return this.resumen.arancel.pagare.estado;
    }

    return 0;
  }

  get estadoDisplayArancel() {
    // pago contado
    if (!this.resumen.arancel.pago_cuotas && this.resumen.arancel.orden_compra) {
      return this.resumen.arancel.orden_compra.estado_pago_display;
    }

    // pago en cuotas - pagaré
    if (this.resumen.arancel.pagare) {
      return this.resumen.arancel.pagare.estado_display;
    }

    return '???';
  }

  get montoArancel() {
    // pago contado
    if (!this.resumen.arancel.pago_cuotas && this.resumen.arancel.orden_compra) {
      return this.resumen.arancel.orden_compra.monto_total
        - this.resumen.arancel.orden_compra.total_descuento_beneficios;
    }

    // pago en cuotas - pagaré
    if (this.resumen.arancel.pagare) {
      return this.resumen.arancel.pagare.monto_arancel;
    }

    return '???';
  }

  get procesoListo() {
    return this.estadoMatricula === 1
      && this.estadoArancel === 1
      && this.$store.getters.esPostulante;
  }

  salir() {
    this.$store.commit('logout');
    this.$router.push({ name: this.$store.getters.linkInicio });
  }
}
