import api from '@/assets/api';

const root = 'certificados';

interface CertificadoI {
  id: number;
  persona_nombre: string;
  persona_rut: string;
  carrera: number;
  carrera_nombre: string;
  fecha_emision: string;
  tipo_certificado: number;
  tipo_nombre: string;
  valido: boolean;
  url_archivo: string;
  nombre_archivo: string;
}

function getCertificado(folio: string, llave: string): Promise<CertificadoI> {
  return new Promise((resolve, reject) => {
    api.post(`${root}/validar-certificado/`, { folio, llave })
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

export {
  CertificadoI, getCertificado,
};
