
import { Component, Vue } from 'vue-property-decorator';
import { Route, NavigationGuardNext } from 'vue-router';

import { PeriodoMatriculaI, getPeriodosMatricula } from '@/assets/api/matricula';

@Component
export default class EduContinuaCalendario extends Vue {
  periodos: PeriodoMatriculaI[] = [];

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<EduContinuaCalendario>) {
    getPeriodosMatricula(2)
      .then((periodos) => {
        next((vm) => {
          // eslint-disable-next-line no-param-reassign
          vm.periodos = periodos;
        });
      });
  }

  dia = (fecha: Date) => fecha.toLocaleString('es-cl', { day: '2-digit' });

  mes = (fecha: Date) => fecha.toLocaleString('es-cl', { month: 'long' });

  hora = (fecha: Date) => fecha.toLocaleString('es-cl', { hour: '2-digit', minute: '2-digit' });
}
