
import { Component, Vue } from 'vue-property-decorator';
import { Route, NavigationGuardNext } from 'vue-router';

import axios from 'axios';

import FondoDiagonal from '@/componentes/UI/FondoDiagonal.vue';
import { CertificadoI, getCertificado } from '@/assets/api/certificados';

@Component({ components: { FondoDiagonal } })
export default class ValidarCert extends Vue {
  error = '';

  cert: CertificadoI | null = null;

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<ValidarCert>) {
    const { folio } = to.params as { folio: string };
    const { llave } = to.params as { llave: string };

    if (!folio || !llave) {
      next((vm) => {
        // eslint-disable-next-line no-param-reassign
        vm.error = 'debe ingresar un folio y llave';
      });
      return;
    }

    getCertificado(folio, llave)
      .then((cert) => {
        next((vm: ValidarCert) => {
          // eslint-disable-next-line no-param-reassign
          vm.cert = cert;
        });
      })
      .catch(() => {
        next((vm: ValidarCert) => {
          // eslint-disable-next-line no-param-reassign
          vm.error = 'no se ha encontrado el certificado solicitado';
        });
      });
  }

  fechaCompleta = (fechaString: string) => {
    const fecha = new Date(fechaString);
    return fecha.toLocaleString(
      'es-cl',
      {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    );
  }

  // descarga archivo utilizando axios y después simula descarga utilizando un blob
  descargarArchivo() {
    if (!this.cert) {
      return;
    }

    axios.get(this.cert?.url_archivo, { responseType: 'blob' })
      .then((response) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute('download', this.cert?.nombre_archivo || 'certificado.pdf');
        document.body.appendChild(link);
        link.click();
      });
  }
}
