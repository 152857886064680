
import { Component, Vue } from 'vue-property-decorator';
import { Route, NavigationGuardNext } from 'vue-router';

import store from '@/store';

import FondoDiagonal from '@/componentes/UI/FondoDiagonal.vue';
import ProgresoPasos from '@/componentes/ProgresoPasos.vue';

import { getEstadoPagoMatricula, getEstadoPagoArancel, ComprobanteI } from '@/assets/api/pagos';

@Component({
  components: {
    FondoDiagonal, ProgresoPasos,
  },
})
export default class EstadoPagoWebpay extends Vue {
  mensaje = '';

  comprobante: ComprobanteI | null = null;

  aprobado = false;

  tipoPago: { [tipo: string]: string } = {
    VD: 'Venta Débito',
    VN: 'Venta Normal',
    VC: 'Venta en cuotas',
    SI: '3 cuotas sin interés',
    S2: '2 cuotas sin interés',
    NC: 'N Cuotas sin interé',
    VP: 'Venta Prepago',
  }

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<EstadoPagoWebpay>) {
    // evitar acceso no autenticado
    if (!store.state.sesion) {
      next({ name: store.getters.linkInicio });
      return;
    }

    // redirigir en caso de estar en otra etapa (permitir volver desde resumen)
    // puede estar en las etapas 2, 3 y 4
    if (store.getters.etapaEstudiante === 0) {
      next({ name: store.getters.linkBienvenida });
      return;
    }
    if (store.getters.etapaEstudiante === 1) {
      next({ name: 'perfil estudiante' });
      return;
    }

    let promesaEstadoPago;
    if (to.name === 'estado pago matricula') {
      promesaEstadoPago = getEstadoPagoMatricula(store.getters.idEstudiante);
    } else {
      promesaEstadoPago = getEstadoPagoArancel(store.getters.idEstudiante);
    }

    Promise.all([promesaEstadoPago])
      .then(([estadoPago]) => {
        next((vm) => {
          /* eslint-disable no-param-reassign */
          vm.mensaje = estadoPago.mensaje;
          vm.aprobado = estadoPago.estado === 'OK';
          vm.comprobante = estadoPago.comprobante_pago;
          /* eslint-enable no-param-reassign */
        });
      });
  }

  get producto() {
    return this.$route.name === 'estado pago matricula' ? 'matricula' : 'arancel';
  }

  get siguiente() {
    if (this.$route.name === 'estado pago matricula' && this.$store.getters.etapaEstudiante === 3) {
      return store.getters.linkPagoArancel;
    }
    return 'resumen';
  }

  get anterior() {
    return this.$route.name === 'estado pago matricula' ? store.getters.linkPagoMatricula : store.getters.linkPagoArancel;
  }
}
