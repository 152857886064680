
import { Component, Vue } from 'vue-property-decorator';

const PropsFondo = Vue.extend({
  props: {
    // define si este es el fondo de la página de inicio o no
    inicio: {
      type: Boolean,
      default: false,
    },
  },
});

@Component
export default class FondoDiagonal extends PropsFondo {}
