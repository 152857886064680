
import { Component, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';

interface OpcionI {
  nombre: string;
  valor: number;
  descripcion: string;
  descripcion_bajada: string;
}

const PropsEleccion = Vue.extend({
  props: {
    titulo: {
      type: String,
      default: '',
    },
    valor: {
      type: Number,
      default: null,
    },
    opciones: {
      type: Array as PropType<Array<OpcionI>>,
      default: () => [],
    },
  },
});

@Component
export default class Eleccion extends PropsEleccion {
  get opcionElegida() {
    return this.opciones.filter((opcion: OpcionI) => opcion.valor === this.valor)[0];
  }
}
