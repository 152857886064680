<template>
  <div>
    <FondoDiagonal/>

    <div class="contenido">
      <div class="bienvenida">
        <div class="saludo" :style="{ background: $store.getters.colorPlan }">
          Hola:)
          <div class="resaltar-fondo">
            {{ $store.getters.nombre.split(' ')[0] }}
          </div>
        </div>

        <div class="intro" :style="{ background: $store.getters.colorPlan }">
          Aquí comienza tu proceso de matrícula, para la carrera:
        </div>

        <div class="carrera">
          <img class="olitas" src="@/assets/img/olas_verdes.png" alt="">

          <span class="resaltar-carrera">{{ $store.getters.carrera }}</span> UAysén

          <div class="depto">Departamento de {{ $store.getters.depto }}</div>
        </div>
      </div>
      <video-youtube class="marco-video" url="https://www.youtube-nocookie.com/embed/rBP_tly6SQE"/>
      <div class="wrapper-declaracion">
        <form class="declaracion-voluntad" @submit.prevent="siguiente">
          <div class="disclaimer">
            <div class="titulo">¡IMPORTANTE!</div>
            <div class="mensaje">
              <ul>
                <li>
                  “La no concreción del proceso de matrícula de un
                  seleccionado(a) tanto en la primera
                  (17 al 19 de enero) como en la segunda instancia (20 de enero al 26 de enero),
                  implica que renuncia a su vacante, dejando liberado su cupo, sin permitírsele un
                  reclamo posterior”
                </li>
                <li>"Debes leer y aceptar los términos y condiciones de prestación de
                  servicios educacionales para continuar
                  con tu proceso de matrícula
                </li>
              </ul>
            </div>
            <div class="titulo"></div>
          </div>
          <div class="titulo-contrato">Términos y Condiciones</div>
          <div class="contrato-container" ref="pdfContainer">
            <pdf
              class="contrato"
              v-for="i in numPages"
              :key="i"
              :src="src"
              :page="i"
            ></pdf>
          </div>
          <div class="checkbox">
            <input type="checkbox" name="declaracion" id="declaracion" v-model="aceptoVacante">

            <label for="declaracion">
              Acepto la vacante disponible para estudiar en la carrera de
              {{ $store.getters.carrera }} de la Universidad de Aysén.
            </label>
          </div>
          <div class="checkbox">
            <input type="checkbox" name="contrato" id="contrato" v-model="aceptoContrato">
            <label for="contrato">
              Acepto los términos y condiciones de prestación de servicios educacionales.
            </label>
          </div>
          <div class="siguiente">
            <button
              type="submit"
              class="btn boton-accion"
              :disabled="!(aceptoVacante && aceptoContrato) || cargando">
              Siguiente
            </button>
            <div v-if="error" class="error">{{ error }}</div>
          </div>
        </form>
      </div>
    </div>

    <div class="footer">
      <ProgresoPasos :paso="1"/>
    </div>
  </div>
</template>

<script>
// import {Component, Vue} from 'vue-property-decorator';
// import {Route, NavigationGuardNext} from 'vue-router';

import store from '@/store';

import FondoDiagonal from '@/componentes/UI/FondoDiagonal.vue';
import VideoYoutube from '@/componentes/UI/VideoYoutube.vue';
import ProgresoPasos from '@/componentes/ProgresoPasos.vue';

import { aceptarVacante, aceptarContrato } from '@/assets/api/matricula';
// import Contrato from '@/componentes/Contrato.vue';
import pdf from 'vue-pdf';

const loadingTask = pdf.createLoadingTask('terminosycondiciones.pdf');
export default {
  name: 'Contrato',
  components: {
    pdf,
    FondoDiagonal,
    ProgresoPasos,
    VideoYoutube,
  },
  data() {
    return {
      src: loadingTask,
      numPages: undefined,
      aceptoVacante: false,
      aceptoContrato: false,
      cargando: false,
      error: '',
    };
  },
  async created() {
    const doc = await loadingTask.promise;
    this.numPages = doc.numPages;
  },
  beforeRouteEnter(to, from, next) {
    // evitar acceso no autenticado
    if (!store.state.sesion) {
      next({ name: store.getters.linkInicio });
      return;
    }
    // evitar que alguien vuelva a entrar en esta vista
    if (store.getters.idEstudiante) {
      next({ name: 'perfil estudiante' });
    } else {
      next();
    }
  },
  methods: {
    siguiente() {
      if (this.aceptoVacante && this.aceptoContrato) {
        this.cargando = true;
        aceptarContrato().then(() => {
          aceptarVacante(this.$store.state.idPostulante).then((respuesta) => {
            this.$store.commit('actualizarEstudiante', respuesta.estudiante);
            this.$store.commit('actualizarEtapa', 1);
            this.$router.push({ name: 'perfil estudiante' });
          }).catch(() => {
            this.cargando = false;
            this.error = 'Lo sentimos, ha ocurrido un error en el sistema. Por favor intenta nuevamente'
              + 'y si el problema persiste ponte en contacto con el encargado de matrícula';
          });
        }).catch(() => {
          this.cargando = false;
          this.error = 'Lo sentimos, ha ocurrido un error en el sistema. Por favor intenta nuevamente'
            + 'y si el problema persiste ponte en contacto con el encargado de matrícula';
        });
      }
    },
  },
};
</script>

<style scoped>
.contrato-container{
  margin-bottom: 2.5em;
}
.contrato{
  border: 0.5em solid;
  border-radius: 0.5em;
  border-color: var(--verde-institucional);
  margin: 0 auto;
}
.bienvenida {
  margin-right: 20px;
  font-weight: 700;
  color: white;
  font-stretch: condensed;
}

.bienvenida .saludo {
  font-size: 5rem;
  margin-bottom: 30px;
  white-space: nowrap; /* el nombre define el ancho del div */
}

.bienvenida .resaltar-fondo {
  display: inline-block;
  background: #0003;
  border-radius: .3em;
  padding: 0 .2em;
}

.bienvenida .intro {
  max-width: 480px;
  font-size: 2rem;
  min-height: 2.5em;
}

.bienvenida .carrera {
  position: relative;
  margin-top: 30px;
  margin-left: .5em;

  font-size: 4rem;
  color: var(--azul-institucional);
}

.bienvenida .carrera .olitas {
  position: absolute;
  left: 0;
  top: .5em;
  margin-left: -100px;
  width: 80px;
}

.bienvenida .carrera .resaltar-carrera {
  color: var(--verde-institucional);
}

.bienvenida .carrera .depto {
  font-size: 1.5rem;
  font-weight: 500;
}

.marco-video {
  align-self: center;
  flex-grow: 1;
  max-width: 800px;
  min-width: 400px;
  margin: 30px auto 0;
}

.wrapper-declaracion {
  flex: 100%; /* asegurar que la declaración de voluntad siempre esté en una fila aparte */
}

.declaracion-voluntad {
  margin: 50px auto 0;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
}

.declaracion-voluntad .checkbox {
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-stretch: condensed;

  display: flex;
  align-items: center;
}

.declaracion-voluntad .checkbox input {
  --size: 1.2em;
}

.disclaimer {
  margin: 0 auto;
  padding: 0 calc(1em + 20px); /* espacio para decoracion */
  display: flex;
  flex-direction: column;
}

.disclaimer .titulo {
  padding-left: .2em;
  height: 1.3em;
  background: white;

  font-size: 2rem;
  font-weight: 800;
  color: var(--naranjo);
}

.disclaimer .mensaje {
  position: relative;
  background: var(--azul-institucional);
  padding: 2em 1em;

  color: white;
  text-align: justify;
}

/* decoraciones */
.disclaimer .mensaje::before, .disclaimer .mensaje::after {
  content: "";
  position: absolute;
  top: 0;

  height: 100%;
  width: 1em;

  background: inherit;
  box-shadow: 0 0 0 15px white,
  0 0 0 20px var(--azul-institucional);
  z-index: -1;
}

.disclaimer .mensaje::before {
  left: 0;
  margin-left: -1em;
}

.disclaimer .mensaje::after {
  right: 0;
  margin-right: -1em;
}

.siguiente {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
}

.btn {
  font-size: 1.25rem;
}

.titulo-contrato {
  padding-left: .2em;
  margin-top: 2em;
  margin-bottom: 0.5em;
  background: white;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 800;
  color: var(--azul-institucional);
}

@media (max-width: 700px) {
  .bienvenida .saludo {
    font-size: 3rem;
  }

  .bienvenida .intro {
    max-width: 350px;
    font-size: 1.5rem;
  }

  .bienvenida .carrera {
    margin-top: 30px;
    margin-left: 20px;
    font-size: 2.5rem;
  }

  .bienvenida .carrera .olitas {
    width: 60px;
    margin-left: -65px;
  }

  .bienvenida .carrera .depto {
    font-size: 1.25rem;
  }

  .marco-video {
    min-width: 100%;
    padding: 5px;
  }

  .declaracion-voluntad {
    margin-top: 30px;
  }

  .declaracion-voluntad .checkbox {
    margin-bottom: 10px;
    font-size: 1.25em;
  }

  .disclaimer {
    padding: 0 calc(1em + 15px); /* espacio para decoracion */
  }

  .disclaimer .titulo {
    font-size: 1.5em;
  }

  .disclaimer .mensaje {
    padding: 1em 0;
  }

  /* decoraciones */
  .disclaimer .mensaje::before, .disclaimer .mensaje::after {
    width: 1em;
    box-shadow: 0 0 0 10px white,
    0 0 0 15px var(--azul-institucional);
  }

  .btn {
    font-size: 1rem;
  }
}
</style>
