
import { Component, Vue } from 'vue-property-decorator';
import { Route, NavigationGuardNext } from 'vue-router';

import store from '@/store';

import FondoDiagonal from '@/componentes/UI/FondoDiagonal.vue';
import VideoYoutube from '@/componentes/UI/VideoYoutube.vue';
import ProgresoPasos from '@/componentes/ProgresoPasos.vue';

import { aceptarVacanteEducacionContinua } from '@/assets/api/matricula';

@Component({ components: { FondoDiagonal, ProgresoPasos, VideoYoutube } })
export default class Bienvenida extends Vue {
  acepto = false;

  cargando = false;

  error = '';

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<Bienvenida>) {
    // evitar acceso no autenticado
    if (!store.state.sesion) {
      next({ name: store.getters.linkInicio });
      return;
    }

    // evitar que alguien vuelva a entrar en esta vista
    if (store.getters.idEstudiante) {
      next({ name: 'perfil estudiante' });
    } else {
      next();
    }
  }

  siguiente() {
    if (this.acepto) {
      this.cargando = true;
      aceptarVacanteEducacionContinua(this.$store.state.idPostulante)
        .then((respuesta) => {
          this.$store.commit('actualizarEstudiante', respuesta.estudiante);
          this.$store.commit('actualizarEtapa', 1);
          this.$router.push({ name: 'perfil estudiante' });
        }).catch(() => {
          this.cargando = false;
          this.error = 'Lo sentimos, ha ocurrido un error en el sistema. Por favor intenta nuevamente y si el problema persiste ponte en contacto con el encargado de matrícula';
        });
    }
  }
}
