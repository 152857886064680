
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route, NavigationGuardNext } from 'vue-router';

import store from '@/store';

import FondoDiagonal from '@/componentes/UI/FondoDiagonal.vue';
import ProgresoPasos from '@/componentes/ProgresoPasos.vue';
import InformacionPago from '@/componentes/pago/InformacionPago.vue';
import EleccionModalidad from '@/componentes/pago/EleccionModalidad.vue';

import { getMatriculaEstudiante, MatriculaEstudianteI } from '@/assets/api/matricula';
import { postPagoMatricula, RespuestaWebpayI, redirectPostWebpay } from '@/assets/api/pagos';

@Component({
  components: {
    FondoDiagonal, ProgresoPasos, InformacionPago, EleccionModalidad,
  },
})
export default class PagoMatricula extends Vue {
  matricula: MatriculaEstudianteI = {} as MatriculaEstudianteI;

  modalidad: number | null = null;

  modo = 1;

  cargando = false;

  error = '';

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<PagoMatricula>) {
    // evitar acceso no autenticado
    if (!store.state.sesion) {
      next({ name: store.getters.linkInicio });
      return;
    }

    // redirigir en caso de estar en otra etapa (permitir volver desde resumen)
    if (store.getters.etapaEstudiante === 0) {
      next({ name: store.getters.linkBienvenida });
      return;
    }
    if (store.getters.etapaEstudiante === 1) {
      next({ name: 'perfil estudiante' });
      return;
    }
    if (store.getters.etapaEstudiante === 3) {
      next({ name: store.getters.linkPagoArancel });
      return;
    }

    const promesaMatricula = getMatriculaEstudiante(store.getters.idEstudiante);

    Promise.all([promesaMatricula])
      .then(([matricula]) => {
        // redirigir a resumen si ya se completó este paso
        if (matricula.orden_compra && matricula.orden_compra.estado_pago === 1) {
          next({ name: 'resumen' });
          return;
        }

        next((vm) => {
          /* eslint-disable no-param-reassign */
          vm.matricula = matricula;

          // solo permitir métodos virtuales para estudiantes de educación continua
          if (store.getters.esPostulanteEduCont) {
            vm.modalidad = 1;
          }
          /* eslint-enable no-param-reassign */
        });
      });
  }

  get precioMatricula() {
    return this.matricula.monto_final + this.matricula.beneficios_monto_total;
  }

  get montoBeneficios() {
    return this.matricula?.beneficios_monto_total ?? 0;
  }

  get precioFinal() {
    return this.matricula?.monto_final ?? 0;
  }

  @Watch('modalidad')
  cambioModalidad() {
    if (this.modalidad === 2) {
      this.modo = 1; // modo presencial
    } else {
      this.modo = 7; // webpay
    }
  }

  enviar() {
    if (store.getters.idEstudiante && (this.modalidad || this.precioFinal === 0)) {
      this.cargando = true;

      // si el monto final es 0 siempre enviar modo 1 (caja)
      if (this.precioFinal === 0) {
        this.modo = 1;
      }

      postPagoMatricula({
        // eslint-disable-next-line @typescript-eslint/camelcase
        estudiante_id: store.getters.idEstudiante,
        // eslint-disable-next-line @typescript-eslint/camelcase
        forma_pago_id: this.modo,
      }).then((respuesta) => {
        if (this.modo === 7) {
          // redirigir en caso de webpay
          const respWebpay = respuesta as RespuestaWebpayI;
          redirectPostWebpay(respWebpay.url_redirect, respWebpay.token_webpay);
        } else if (store.getters.etapaEstudiante === 4) {
          // si ya completó el proceso llevar directo al resumen
          this.$router.push({ name: 'resumen' });
        } else {
          store.commit('actualizarEtapa', 3);
          this.$router.push({ name: store.getters.linkPagoArancel });
        }
      }).catch((error) => {
        this.cargando = false;

        if (error.mensaje) {
          this.error = error.mensaje;
        } else {
          this.error = 'Lo sentimos, ha ocurrido un error en el sistema. Por favor intenta nuevamente y si el problema persiste ponte en contacto con el encargado de matrícula';
        }
      });
    }
  }
}
