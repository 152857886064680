var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Eleccion',{attrs:{"valor":_vm.modalidad,"titulo":"Elige la modalidad de pago que prefieres","opciones":[
    {
      nombre: 'Modalidad Presencial',
      valor: 2,
      descripcion:
        'Se habilitará el pago por caja en el Campus Lillo 1 de la UAysén, optando a:',
      descripcion_bajada: 'Pago Efectivo / Pago con cheques / Pago con tarjetas',
    },
    {
      nombre: 'Modalidad Remota',
      valor: 1,
      descripcion: 'Continuarás por este sistema virtual, optando a:',
      descripcion_bajada: 'Webpay Plus',
    },
  ]},on:{"update:valor":function($event){return _vm.$emit('update:modalidad', $event)}}})
}
var staticRenderFns = []

export { render, staticRenderFns }