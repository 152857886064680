
import { Component, Vue } from 'vue-property-decorator';

const PropsProgreso = Vue.extend({
  props: {
    // define si este es el fondo de la página de inicio o no
    paso: {
      type: Number,
      default: 1,
    },
  },
});

@Component
export default class ProgesoPasos extends PropsProgreso {
  estadoPaso(pasoActual: number) {
    let esconder = '';
    if (Math.abs(this.paso - pasoActual) > 1) {
      esconder = 'esconder ';
    }

    if (this.paso > pasoActual) {
      return `${esconder}completado`;
    }
    if (this.paso < pasoActual) {
      return `${esconder}pendiente`;
    }
    return `${esconder}activo`;
  }
}
