var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"progreso-pasos"},[_c('div',{staticClass:"puntos"},_vm._l((100),function(i){return _c('span',{key:i,staticClass:"fas fa-circle"})}),0),(_vm.paso !== 5)?_c('div',{staticClass:"pasos"},[_c('div',{staticClass:"paso",class:_vm.estadoPaso(1)},[_c('img',{staticClass:"icono",attrs:{"alt":"icono paso","src":require("@/assets/img/pasos/icono_paso1.png")}}),_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"paso",class:_vm.estadoPaso(2)},[_c('img',{staticClass:"icono",attrs:{"alt":"icono paso","src":require("@/assets/img/pasos/icono_paso2.png")}}),_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"paso",class:_vm.estadoPaso(3)},[_c('img',{staticClass:"icono",attrs:{"alt":"icono paso","src":require("@/assets/img/pasos/icono_paso3.png")}}),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"paso",class:_vm.estadoPaso(4)},[_c('img',{staticClass:"icono",attrs:{"alt":"icono paso","src":require("@/assets/img/pasos/icono_paso4.png")}}),_vm._m(6),_vm._m(7)]),_c('div',{staticClass:"paso",class:_vm.estadoPaso(5)},[_c('img',{staticClass:"icono",attrs:{"alt":"icono paso","src":require("@/assets/img/pasos/icono_paso5.png")}}),_vm._m(8),_vm._m(9)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"numero"},[_vm._v("Paso 1 "),_c('span',{staticClass:"fas fa-check-circle"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"nombre"},[_c('span',{staticClass:"resaltar-texto"},[_vm._v("Ingreso")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"numero"},[_vm._v("Paso 2 "),_c('span',{staticClass:"fas fa-check-circle"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"nombre"},[_vm._v("Perfil "),_c('span',{staticClass:"resaltar-texto"},[_vm._v("Estudiante")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"numero"},[_vm._v("Paso 3 "),_c('span',{staticClass:"fas fa-check-circle"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"nombre"},[_vm._v("Pago "),_c('span',{staticClass:"resaltar-texto"},[_vm._v("Matrícula")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"numero"},[_vm._v("Paso 4 "),_c('span',{staticClass:"fas fa-check-circle"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"nombre"},[_vm._v("Pago "),_c('span',{staticClass:"resaltar-texto"},[_vm._v("Arancel")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"numero"},[_vm._v("Paso 5 "),_c('span',{staticClass:"fas fa-check-circle"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"nombre"},[_c('span',{staticClass:"resaltar-texto"},[_vm._v("Confirmación")])])
}]

export { render, staticRenderFns }