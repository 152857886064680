
import { Component, Vue } from 'vue-property-decorator';

const preguntas = [
  {
    pregunta: '¿Puede matricularme mi “apoderado”?',
    resp: `
      <p>Las personas que cuentan con mayoría de edad, son consideradas autónomas. Por lo anterior, en la Universidad de Aysén no se considera la figura del "apoderado/a" como en la etapa escolar.

      <p>No obstante, si eres menor de edad, uno de tus padres o tu tutor debería ser tu avalista en caso de que firmes pagaré.

      <p>Esto no significa que no recibamos a tus padres y apoderados/as. Ellos te pueden acompañar y ayudar en todo este proceso para que tomes las mejores decisiones y serán más que bienvenidos/as en la UAysén.`,
  }, {
    pregunta: '¿Qué documentos debo presentar al momento de matricularme?',
    resp:
      '<p>No necesitas entregar documentación adicional para completar tu proceso de matrícula online (excepto el pagaré de quienes no tienen gratuidad)',
  }, {
    pregunta: 'Me preseleccionaron para la Gratuidad o cuento con alguna beca completa, ¿cómo me matriculo?',
    resp:
      '<p>Si tienes gratuidad, no debes cancelar nada para aceptar y confirmar tu matrícula. Si tienes una beca que cubra solo arancel, deberás pagar solo la matrícula y continuar con el proceso.',
  }, {
    pregunta: 'No me preseleccionaron para la Gratuidad, pero voy a apelar. ¿Cómo me matriculo?',
    resp:
      '<p>Si aún no tienes la Gratuidad, debes matricularte dentro del plazo para no perder tu vacante. Para ello, debes ingresar al portal de matrícula y seguir los pasos, que implican, actualizar tus datos personales, pagar la matrícula y gestionar el pago del arancel (en cuotas, con pagaré). El monto cancelado para la matrícula será devuelto y el pagaré será anulado si es aprobada tu gratuidad.',
  }, {
    pregunta: '¿Cómo me matriculo si tengo Crédito con Aval del Estado (CAE)?',
    resp:
      '<p>Si vas a estudiar con CAE, debes matricularte dentro del plazo para no perder tu vacante. Para ello, debes ingresar al portal de matrículas y seguir los pasos, que implican, actualizar tus datos personales, pagar la matrícula y gestionar el pago del arancel (en cuotas, con pagaré). El monto a pagar será actualizado una vez que te asignen el CAE, de ser necesario.',
  }, {
    pregunta: '¿Qué opciones tengo para pagar mi matrícula?',
    resp:
      `Puedes pagar tu derecho a matrícula en las siguientes modalidades:

      <ul>
        <li>En línea, con tarjetas de débito o crédito directamente a través del portal.
        <li>Presencialmente en Lillo #667, Coyhaique.
      </ul>`,
  }, {
    pregunta: '¿Qué opciones tengo para pagar mi arancel?',
    resp:
      `El arancel puedes pagarlo en las siguientes modalidades:

      <ul>
        <li>En línea, con tarjetas de débito o crédito directamente a través del portal.
        <li>Presencialmente en Lillo #667, Coyhaique.
        <li>En cuotas con pagaré legalizado
      </ul>`,
  }, {
    pregunta: '¿Tengo descuento al pagar al contado el arancel anual?',
    resp: 'Todos quienes paguen el arancel anual en su totalidad, tendrán un descuento del 5%.',
  }, {
    pregunta: '¿Qué debo considerar antes de legalizar el pagaré?',
    resp:
      `Ten en cuenta que:

      <ul>
        <li>El pagaré deberás obtenerlo en este portal, al momento de seleccionar tu forma de pago.
        <li>Deberás imprimirlo en hoja tamaño carta, sin uso ni reciclada, y la calidad de impresión debe ser óptima, sin enmendaduras (manchas, borrones, errores de impresión) de ningún tipo.
        <li>Deberás legalizarlo en una notaría pública, junto a tu avalista y en caso de que seas menor de 18 años, también con un representante legal. Sin estas firmas, el pagaré será rechazado.
        <li>Si tienes discapacidad visual y no te encuentras en condiciones de firmar el documento, deberás estampar tu huella digital y legalizar el pagaré.
        <li>Tú no puedes ser tu avalista, debe ser una persona mayor de 18 años (chilena o extranjera con residencia definitiva) que no sea estudiante regular de la UAysén.
      </ul>`,
  }, {
    pregunta: '¿Cómo legalizo el pagaré?',
    resp:
      `Si elegiste pagar en cuotas tu arancel a través de un pagaré, deberás obtenerlo en el sitio www.uaysen.cl o en https://matricula.uaysen.cl una vez que seas convocado/a a matrícula y seguir estos pasos:

      <ol>
        <li>Imprime el pagaré.
        <li>Asiste a cualquier notaría pública del país, junto con tu avalista, y solicita el servicio de legalización.
        <li>Una vez legalizado el pagaré, deberás entregarlo a la Universidad antes del 30 de marzo presencialmente en la Universidad de Aysén o enviarlo por correo certificado, adjuntando una copia escaneada del pagaré  y el ticket o comprobante de envío al mail blanca.gonzalez@uaysen.cl.
      </ol>`,
  }, {
    pregunta: 'Vivo fuera de Coyhaique, ¿cómo me matriculo si elijo la opción de pagaré?',
    resp:
      `<p>Los estudiantes de fuera de Coyhaique podrán legalizar el pagaré en su ciudad de origen y enviarlo por correo certificado, siguiendo los pasos indicados.

      <p>Una vez legalizado el pagaré, deberás entregarlo a la Universidad antes del 30 de marzo presencialmente en la Universidad de Aysén o enviarlo por correo certificado, adjuntando una copia escaneada del pagaré  y el ticket o comprobante de envío al mail blanca.gonzalez@uaysen.cl.`,
  }, {
    pregunta: 'Soy menor de edad, ¿cómo me matriculo si elijo la opción de pagaré?',
    resp:
      '<p>Si eres menor de edad, no puedes contraer compromisos legales o financieros, por lo que no puedes firmar un pagaré. En su lugar, deberá firmarlo tu representante legal o tutor (padre, madre, tutor legal, etc).',
  }, {
    pregunta: '¿Qué debo hacer después de matricularme?',
    resp:
      `<p>Una vez completada la matrícula, debes cumplir con algunos importantes procesos:

      <ul>
        <li>Revisa los correos que has recibido.
        <li>Activar tu correo institucional.
        <li>Contestar la Encuesta de caracterización a la brevedad.
        <li>Tomarte la fotografía para la Tarjeta Nacional Estudiantil (TNE), de acuerdo a las intrucciones que se entregarán.
        <li>Realizar la evaluación diagnóstica.
        <li>Participar en el proceso de nivelación.
      </ul>

      <p>Toda esta información se te será entregada oportunamente en tu correo personal o institucional (una vez lo hayas activado).`,
  },
];

@Component
export default class Calendario extends Vue {
  preguntas = preguntas
}
