
import { Component, Vue } from 'vue-property-decorator';

const PropsTabsInformacion = Vue.extend({
  props: {
    // define los links a utilizar en cada tab. Si un link no está agregado se omite.
    calendario: {
      type: String,
      default: '',
    },
    guiaPasos: {
      type: String,
      default: '',
    },
    contacto: {
      type: String,
      default: '',
    },
    preguntasFrecuentes: {
      type: String,
      default: '',
    },
  },
});

@Component
export default class TabsInformacion extends PropsTabsInformacion {
  get colorCorchetes() {
    if (this.$route.name?.includes('educontinua')) {
      return 'var(--verde-institucional)';
    }
    return 'var(--azul-institucional)';
  }
}
