<template>
  <div>
    <FondoDiagonal />
    <div class="contenido">
      <div class="wrapper-declaracion">
        <form class="declaracion-voluntad" @submit.prevent="siguiente">
          <div class="disclaimer">
            <div class="titulo">¡IMPORTANTE!</div>
            <div class="mensaje">
              "Deberás leer y aceptar los términos y condiciones de
              prestación de servicios educacionales para continuar
              con tu proceso de matrícula"
            </div>
            <div class="titulo"></div>
          </div>
          <div class="titulo-contrato">Términos y Condiciones</div>
          <div class="contenido">
            <!-- font awesome icon info -->
            <div class="info">
              <i class="fas fa-info-circle">
              </i>
              Si no puedes visualizar el documento, por favor recarga la página.
            </div>
          </div>
          <div class="contrato-container" ref="pdfContainer">
            <pdf
              class="contrato"
              v-for="i in numPages"
              :key="i"
              :src="src"
              :page="i"
            ></pdf>
          </div>
          <div class="checkbox">
            <input type="checkbox" name="contrato" id="contrato" v-model="aceptoContrato">
            <label for="contrato">
              Acepto los términos y condiciones de prestación de servicios educacionales.
            </label>
          </div>
          <div class="siguiente">
            <button
              type="submit"
              class="btn boton-accion"
              :disabled="!aceptoContrato || cargando">
              Siguiente
            </button>
            <div v-if="error" class="error">{{ error }}</div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import FondoDiagonal from '@/componentes/UI/FondoDiagonal.vue';
import { aceptarContrato } from '@/assets/api/matricula';
import store from '@/store';
import pdf from 'vue-pdf';

const loadingTask = pdf.createLoadingTask('terminosycondiciones.pdf');

export default {
  name: 'ContratoAntiguos',
  components: { pdf, FondoDiagonal },
  data() {
    return {
      src: loadingTask,
      numPages: undefined,
      aceptoContrato: false,
      cargando: false,
      error: '',
    };
  },
  beforeRouteEnter(to, from, next) {
    // evitar acceso no autenticado
    if (!store.state.sesion) {
      next({ name: 'login antiguos' });
    } else {
      next();
    }
  },
  async created() {
    const doc = await loadingTask.promise;
    this.numPages = doc.numPages;
  },
  methods: {
    async siguiente() {
      this.cargando = true;
      this.error = '';
      try {
        await aceptarContrato();
        store.commit('actualizaAceptaContrato', true);
        store.commit('actualizarEtapa', 1);
        await this.$router.push({ name: 'perfil estudiante' });
      } catch (e) {
        this.error = e.message;
      } finally {
        this.cargando = false;
      }
    },
  },
};
</script>

<style scoped>
.info{
  font-size: 1em;
  font-weight: 800;
  color: var(--azul-institucional);
}
.info i{
  margin-right: 0.5em;
  font-size: 1em;
  color: var(--amarillo-social);
}
.contrato-container{
  margin-bottom: 2.5em;
}
.contrato{
  border: 0.5em solid;
  border-radius: 0.5em;
  border-color: var(--verde-institucional);
  margin: 0 auto;
}
.contenido {
  justify-content: center;
}

.wrapper-declaracion {
  flex: 100%; /* asegurar que la declaración de voluntad siempre esté en una fila aparte */
}
.declaracion-voluntad {
  margin: 18em auto 0;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
}
.declaracion-voluntad .checkbox {
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-stretch: condensed;

  display: flex;
  align-items: center;
}
.declaracion-voluntad .checkbox input {
  --size: 1.2em;
}

.disclaimer {
  margin: 0 auto;
  padding: 0 calc(1em + 20px); /* espacio para decoracion */
  display: flex;
  flex-direction: column;
}
.disclaimer .titulo {
  padding-left: .2em;
  height: 1.3em;
  background: white;

  font-size: 2rem;
  font-weight: 800;
  color: var(--naranjo);
}
.disclaimer .mensaje {
  position: relative;
  background: var(--azul-institucional);
  padding: 2em 1em;

  color: white;
  text-align: justify;
}

/* decoraciones */
.disclaimer .mensaje::before, .disclaimer .mensaje::after {
  content: "";
  position: absolute;
  top: 0;

  height: 100%;
  width: 1em;

  background: inherit;
  box-shadow:
    0 0 0 15px white,
    0 0 0 20px var(--azul-institucional);
  z-index: -1;
}
.disclaimer .mensaje::before {
  left: 0;
  margin-left: -1em;
}
.disclaimer .mensaje::after {
  right: 0;
  margin-right: -1em;
}

.siguiente {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
}
.btn {
  font-size: 1.25rem;
}
.titulo-contrato {
  padding-left: .2em;
  margin-top: 2em;
  margin-bottom: 0.5em;
  background: white;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 800;
  color: var(--azul-institucional);
}

@media (max-width: 700px) {
  .declaracion-voluntad {
    margin-top: 30px;
  }
  .declaracion-voluntad .checkbox {
    margin-bottom: 10px;
    font-size: 1.25em;
  }

  .disclaimer {
    padding: 0 calc(1em + 15px); /* espacio para decoracion */
  }
  .disclaimer .titulo {
    font-size: 1.5em;
  }
  .disclaimer .mensaje {
    padding: 1em 0;
  }

  /* decoraciones */
  .disclaimer .mensaje::before, .disclaimer .mensaje::after {
    width: 1em;
    box-shadow:
      0 0 0 10px white,
      0 0 0 15px var(--azul-institucional);
  }
  .btn {
    font-size: 1rem;
  }
}
</style>
