
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route, NavigationGuardNext } from 'vue-router';

import { loginPostulante } from '@/assets/api/matricula';

import FondoDiagonal from '@/componentes/UI/FondoDiagonal.vue';
import FooterLinks from '@/componentes/UI/FooterLinks.vue';
import TabsInformacion from '@/componentes/UI/TabsInformacion.vue';

@Component({ components: { FondoDiagonal, FooterLinks, TabsInformacion } })
export default class Inicio extends Vue {
  rut = '';

  numeroDemre = '';

  mostrarAclaracion = false;

  error = '';

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<Inicio>) {
    next((vm) => {
      vm.$store.commit('logout');
    });
  }

  @Watch('rut')
  cambioRut() {
    this.rut = this.rut.replace(/[^0-9 | \- | k]/, '');
  }

  @Watch('numeroDemre')
  cambioNumero() {
    this.numeroDemre = this.numeroDemre.replace(/[^0-9]/, '');
  }

  login() {
    // eslint-disable-next-line @typescript-eslint/camelcase
    loginPostulante({ rut: this.rut, numero_demre: this.numeroDemre })
      .then((data) => {
        this.$store.commit('loginPostulante', data);

        if (this.$store.getters.etapaEstudiante === 1) {
          this.$router.push({ name: 'perfil estudiante' });
        } else if (this.$store.getters.etapaEstudiante === 2) {
          this.$router.push({ name: this.$store.getters.linkPagoMatricula });
        } else if (this.$store.getters.etapaEstudiante === 3) {
          this.$router.push({ name: this.$store.getters.linkPagoArancel });
        } else if (this.$store.getters.etapaEstudiante === 4) {
          this.$router.push({ name: 'resumen' });
        } else {
          this.$router.push({ name: this.$store.getters.linkBienvenida });
        }
      })
      .catch((error) => {
        this.error = error.mensaje;
      });
  }
}
