import api from '@/assets/api';

const root = 'pagare';

interface ModalidadCuotaI {
  id: number;
  proceso_matricula: number;
  plan: number;
  cantidad_cuotas: number;
  curso_asociado_display: string;
  link_pagare: string;
  fecha_primera_cuota: string;
}

function getModalidadesCuotas(plan: number, curso: number): Promise<ModalidadCuotaI[]> {
  return new Promise((resolve, reject) => {
    api.get(`${root}/modalidades-cuotas/`, { params: { plan, curso } })
      .then((respuesta) => resolve(respuesta.data))
      .catch((error) => { reject(error.response.data); });
  });
}

export {
  ModalidadCuotaI, getModalidadesCuotas,
};
