
import { Component, Vue } from 'vue-property-decorator';
import { Route, NavigationGuardNext } from 'vue-router';

import store from '@/store';

import FondoDiagonal from '@/componentes/UI/FondoDiagonal.vue';
import ProgresoPasos from '@/componentes/ProgresoPasos.vue';

import {
  getInhabilitantesMatricula, InhabilitantesMatriculaI, comenzarProcesoEstudianteAntiguo,
} from '@/assets/api/matricula';

@Component({ components: { FondoDiagonal, ProgresoPasos } })
export default class InhabilitantesAntiguos extends Vue {
  inhabilitantes: InhabilitantesMatriculaI = {} as InhabilitantesMatriculaI;

  deudaFinanzas = false;

  deudaBiblioteca = false;

  cargando = false;

  error = '';

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<InhabilitantesAntiguos>) {
    if (!store.state.sesion) {
      next({ name: store.getters.linkInicio });
      return;
    }
    // redirigir a la vista correspondiente
    if (store.getters.aceptaContrato) {
      if (store.getters.etapaEstudiante === 1) {
        next({ name: 'perfil estudiante' });
        return;
      }
      if (store.getters.etapaEstudiante === 2) {
        next({ name: store.getters.linkPagoMatricula });
        return;
      }
      if (store.getters.etapaEstudiante === 3) {
        next({ name: store.getters.linkPagoArancel });
        return;
      }
      if (store.getters.etapaEstudiante === 4) {
        next({ name: 'resumen' });
      }
    }
    getInhabilitantesMatricula(store.getters.idEstudiante)
      .then((inhabilitantes) => {
        next((vm) => {
          // eslint-disable-next-line no-param-reassign
          vm.inhabilitantes = inhabilitantes;
          // eslint-disable-next-line no-param-reassign
          vm.deudaFinanzas = inhabilitantes.tiene_deuda_finanzas;
          // eslint-disable-next-line no-param-reassign
          vm.deudaBiblioteca = inhabilitantes.tiene_deuda_biblioteca;
        });
      });
  }

  siguiente() {
    if (this.deudaBiblioteca || this.deudaFinanzas) {
      return;
    }

    this.cargando = true;
    comenzarProcesoEstudianteAntiguo(store.getters.idEstudiante)
      .then(() => {
        // store.commit('actualizarEtapa', 1);

        // si ya se llegó al resumen retornar a ese paso
        if (store.getters.etapaEstudiante === 4) {
          this.$router.push({ name: 'resumen' });
        } else if (this.$store.getters.aceptaContrato) {
          this.$router.push({ name: 'perfil estudiante' });
        } else {
          this.$router.push({ name: 'contrato antiguos' });
        }
      }).catch(() => {
        this.cargando = false;
        this.error = 'Lo sentimos, ha ocurrido un error en el sistema. Por favor intenta nuevamente y si el problema persiste ponte en contacto con el encargado de matrícula';
      });
  }

  salir() {
    store.commit('logout');
    this.$router.push({ name: store.getters.linkInicio });
  }
}
