
import { Component, Vue } from 'vue-property-decorator';

import Eleccion from '@/componentes/pago/Eleccion.vue';

const PropsEleccionModalidad = Vue.extend({
  props: {
    modalidad: {
      type: Number,
      default: null,
    },
  },
});

@Component({ components: { Eleccion } })
export default class EleccionModalidad extends PropsEleccionModalidad {}
