
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route, NavigationGuardNext } from 'vue-router';

import store from '@/store';

import FondoDiagonal from '@/componentes/UI/FondoDiagonal.vue';
import ProgresoPasos from '@/componentes/ProgresoPasos.vue';

import Eleccion from '@/componentes/pago/Eleccion.vue';
import EleccionModalidad from '@/componentes/pago/EleccionModalidad.vue';
import InformacionPago from '@/componentes/pago/InformacionPago.vue';

import {
  getArancelEstudiante, postAceptarPagare, ArancelEstudianteI, getDetallesPeriodo,
  DetallesPeriodoI,
} from '@/assets/api/matricula';
import { ModalidadCuotaI, getModalidadesCuotas } from '@/assets/api/pagare';
import { postPagoArancel, RespuestaWebpayI, redirectPostWebpay } from '@/assets/api/pagos';

@Component({
  components: {
    FondoDiagonal, ProgresoPasos, InformacionPago, Eleccion, EleccionModalidad,
  },
})
export default class PagoArancel extends Vue {
  arancel: ArancelEstudianteI = {} as ArancelEstudianteI;

  dctoOportuno = 0;

  formaPago: number | null = null;

  modalidad: number | null = null;

  modo = 1;

  modalidadesCuotas: ModalidadCuotaI[] = [];

  modalidadCuota = -1;

  detallesPeriodo: DetallesPeriodoI = {} as DetallesPeriodoI;

  cargando = false;

  error = '';

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<PagoArancel>) {
    // evitar acceso no autenticado
    if (!store.state.sesion) {
      next({ name: store.getters.linkInicio });
      return;
    }

    // redirigir en caso de estar en otra etapa (permitir volver desde resumen)
    if (store.getters.etapaEstudiante === 0) {
      next({ name: store.getters.linkBienvenida });
      return;
    }
    if (store.getters.etapaEstudiante === 1) {
      next({ name: 'perfil estudiante' });
      return;
    }
    if (store.getters.etapaEstudiante === 2) {
      next({ name: store.getters.linkPagoMatricula });
      return;
    }

    const promesaArancel = getArancelEstudiante(store.getters.idEstudiante);
    const promesaDetalles = getDetallesPeriodo(store.getters.idEstudiante);
    const promesaModalidades = getModalidadesCuotas(
      store.getters.idPlan,
      store.getters.esEstudianteAntiguo ? 1 : 0,
    );

    Promise.all([promesaArancel, promesaDetalles, promesaModalidades])
      .then(([arancel, detallesPeriodo, modalidades]) => {
        // redirigir a resumen si es que no existe un periodo abierto
        if (!detallesPeriodo) {
          next({ name: 'resumen' });
          return;
        }

        // redirigir a resumen si ya se completó este paso
        if ((arancel.orden_compra && arancel.orden_compra.estado_pago === 1)
            || (arancel.pagare && arancel.pagare.estado === 1)) {
          next({ name: 'resumen' });
          return;
        }

        next((vm) => {
          /* eslint-disable no-param-reassign */
          vm.arancel = arancel;
          vm.modalidadesCuotas = modalidades;
          vm.detallesPeriodo = detallesPeriodo;
          vm.dctoOportuno = detallesPeriodo.dctoOportuno;

          if (store.getters.esPostulanteEduCont) {
            vm.modalidad = 1; // solo métodos remotos
          }

          if (modalidades.length === 0) {
            vm.formaPago = 1; // solo pago contado si no hay pagarés registrados
          } else {
            vm.modalidadCuota = modalidades[0].id;
          }
          /* eslint-enable no-param-reassign */
        });
      });
  }

  get montoOriginal() {
    return this.arancel.monto + this.arancel.beneficios_monto_total;
  }

  get montoBeneficios() {
    return this.arancel?.beneficios_monto_total ?? 0;
  }

  get montoArancel() {
    return this.arancel?.monto ?? 0;
  }

  get montoDescuento() {
    return Math.round(this.arancel.monto * (this.dctoOportuno / 100));
  }

  get montoFinal() {
    if (this.formaPago === 1) {
      return this.arancel.monto - this.montoDescuento;
    }
    return this.arancel.monto;
  }

  get tienePagare() {
    return this.modalidadesCuotas.length > 0;
  }

  @Watch('formaPago')
  cambioFormaPago() {
    if (this.formaPago === 2) {
      this.modalidad = 3; // pagaré
    } else if (store.getters.esPostulanteEduCont) {
      this.modalidad = 1; // si es educacion continua solo permitir pago online
    } else {
      this.modalidad = null; // deseleccionar
    }
  }

  @Watch('modalidad')
  cambioModalidad() {
    if (this.modalidad === 2) {
      this.modo = 1; // modo presencial
    } else if (this.modalidad === 1) {
      this.modo = 7; // webpay
    }
  }

  enviar() {
    if (store.getters.idEstudiante && (this.formaPago || this.montoFinal === 0)) {
      this.cargando = true;

      if (this.formaPago === 1 || this.montoFinal === 0) { // pago contado
        // si el monto final es 0 siempre enviar modo 1 (caja)
        if (this.montoFinal === 0) {
          this.modo = 1;
        }

        postPagoArancel({
          // eslint-disable-next-line @typescript-eslint/camelcase
          estudiante_id: store.getters.idEstudiante,
          // eslint-disable-next-line @typescript-eslint/camelcase
          forma_pago_id: this.modo,
        }).then((respuesta) => {
          if (this.modo === 7) {
            // redirigir en caso de webpay
            const respWebpay = respuesta as RespuestaWebpayI;
            redirectPostWebpay(respWebpay.url_redirect, respWebpay.token_webpay);
          } else {
            store.commit('actualizarEtapa', 4);
            this.$router.push({ name: 'resumen' });
          }
        }).catch((error) => {
          this.cargando = false;

          if (error.mensaje) {
            this.error = error.mensaje;
          } else {
            this.error = 'Lo sentimos, ha ocurrido un error en el sistema. Por favor intenta nuevamente y si el problema persiste ponte en contacto con el encargado de matrícula';
          }
        });
      } else { // pagaré
        postAceptarPagare({
          estudiante: store.getters.idEstudiante,
          modalidad: this.modalidadCuota,
        }).then(() => {
          store.commit('actualizarEtapa', 4);
          this.$router.push({ name: 'resumen' });
        }).catch((error) => {
          this.cargando = false;

          if (error.mensaje) {
            this.error = error.mensaje;
          } else {
            this.error = 'Lo sentimos, ha ocurrido un error en el sistema. Por favor intenta nuevamente y si el problema persiste ponte en contacto con el encargado de matrícula';
          }
        });
      }
    }
  }
}
