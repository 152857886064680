import Vue from 'vue';
import Vuex from 'vuex';

import api from '@/assets/api';
import {
  PlanI, PostulanteI, validarTokenPostulante, EstudianteI, validarTokenEstudianteAntiguo,
  PostulanteEduContI, validarTokenEducacionContinua,
} from '@/assets/api/matricula';

Vue.use(Vuex);

const llaveToken = 'token';
const llaveTipoUsuario = 'tipo_usuario';
const llaveIdUsuario = 'id_usuario';

const tiposUsuario = {
  NULO: 0,
  POSTULANTE: 1,
  ESTUDIANTE: 2,
  EDUCONTINUA: 3,
};

const colorPlan: { [plan: number]: string } = {
  0: 'var(--azul-institucional)',
  1: 'var(--azul-enfermeria)',
  2: 'var(--rojo-obstetricia)',
  3: 'var(--verde-agronomia)',
  4: 'var(--verde-forestal)',
  5: 'var(--naranjo-industrial)',
  6: 'var(--amarillo-social)',
  7: 'var(--celeste-psicologia)',
  8: 'var(--rosado-informatica)',
};

const planesOscuros = [0, 1, 2, 4, 8];

const store = new Vuex.Store({
  strict: true,

  state: {
    tipoUsuario: tiposUsuario.NULO,

    nombre: '',
    plan: {} as PlanI,
    idEstudiante: null as number | null,
    etapaEstudiante: 0,
    idPostulante: null as number | null,
    aceptaContrato: false,
    sesion: false,
  },

  getters: {
    // tipo usuario
    esPostulante: (state) => state.tipoUsuario === tiposUsuario.POSTULANTE,

    esEstudianteAntiguo: (state) => state.tipoUsuario === tiposUsuario.ESTUDIANTE,

    esPostulanteEduCont: (state) => state.tipoUsuario === tiposUsuario.EDUCONTINUA,

    // datos de estudiante
    idEstudiante: (state) => state.idEstudiante,
    idPlan: (state) => state.plan.id,
    nombre: (state) => state.nombre,
    carrera: (state) => state.plan.carrera,
    aceptaContrato: (state) => state.aceptaContrato,
    depto: (state) => state.plan.depto,

    etapaEstudiante: (state) => state.etapaEstudiante,

    // consultas de colores de carrera
    colorPlan: (state, getters) => colorPlan[getters.idPlan || 0],

    esPlanOscuro: (state, getters) => planesOscuros.includes(getters.idPlan || 0),

    colorTextoPlan: (state, getters) => {
      if ([2, 3, 5, 6, 7, 8].includes(getters.idPlan)) {
        return '#222';
      }
      if ([0, 1, 4].includes(getters.idPlan)) {
        return 'var(--celeste-institucional)';
      }
      return 'var(--azul-institucional)';
    },

    // páginas según tipo usuario
    linkInicio: (state, getters) => {
      if (getters.esPostulanteEduCont) {
        return 'educontinua inicio';
      }
      return 'inicio';
    },

    linkBienvenida: (state, getters) => {
      if (getters.esPostulante) {
        return 'bienvenida';
      }
      if (getters.esEstudianteAntiguo) {
        return 'inhabilitantes antiguos';
      }
      return 'educontinua bienvenida';
    },

    linkPagoMatricula: () => 'pago matricula',

    linkPagoArancel: () => 'pago arancel',
  },

  mutations: {
    loginPostulante(state, data: { postulante: PostulanteI; accessToken: string }) {
      state.sesion = true;
      state.tipoUsuario = tiposUsuario.POSTULANTE;

      state.nombre = data.postulante.nombre;
      state.plan = data.postulante.plan;
      state.idEstudiante = data.postulante.estudiante;
      state.etapaEstudiante = data.postulante.etapa_estudiante;
      state.idPostulante = data.postulante.id;

      api.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
      localStorage.setItem(llaveToken, data.accessToken);
      localStorage.setItem(llaveTipoUsuario, tiposUsuario.POSTULANTE.toString());
      localStorage.setItem(llaveIdUsuario, data.postulante.id.toString());
    },

    loginEstudiante(state, data: { estudiante: EstudianteI; accessToken: string }) {
      state.sesion = true;
      state.tipoUsuario = tiposUsuario.ESTUDIANTE;

      state.nombre = data.estudiante.nombre;
      state.aceptaContrato = data.estudiante.acepta_contrato;
      state.plan = data.estudiante.plan;
      state.idEstudiante = data.estudiante.id;
      state.etapaEstudiante = data.estudiante.etapa_estudiante;

      api.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
      localStorage.setItem(llaveToken, data.accessToken);
      localStorage.setItem(llaveTipoUsuario, tiposUsuario.ESTUDIANTE.toString());
      localStorage.setItem(llaveIdUsuario, data.estudiante.id.toString());
    },

    loginPostulanteEduCont(state, data: { postulante: PostulanteEduContI; accessToken: string }) {
      state.sesion = true;
      state.tipoUsuario = tiposUsuario.EDUCONTINUA;

      state.nombre = data.postulante.nombre;
      state.plan = data.postulante.plan;
      state.idEstudiante = data.postulante.estudiante;
      state.etapaEstudiante = data.postulante.etapa_estudiante;
      state.idPostulante = data.postulante.id;

      api.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
      localStorage.setItem(llaveToken, data.accessToken);
      localStorage.setItem(llaveTipoUsuario, tiposUsuario.EDUCONTINUA.toString());
      localStorage.setItem(llaveIdUsuario, data.postulante.id.toString());
    },

    actualizarEstudiante(state, estudiante: number) {
      state.idEstudiante = estudiante;
    },

    actualizarNombre(state, nombre: string) {
      state.nombre = nombre;
    },
    actualizaAceptaContrato(state, aceptaContrato: boolean) {
      state.aceptaContrato = aceptaContrato;
    },
    // solo aumentar la etapa a medida que se avanza, si se retrocede a un paso no se retrocede
    // la etapa
    actualizarEtapa(state, etapa: number) {
      state.etapaEstudiante = Math.max(etapa, state.etapaEstudiante || 0);
    },

    logout(state) {
      state.sesion = false;
      state.tipoUsuario = tiposUsuario.NULO;

      state.nombre = '';
      state.plan = {} as PlanI;
      state.idEstudiante = null;
      state.etapaEstudiante = 0;

      api.defaults.headers.common.Authorization = null;
      localStorage.removeItem(llaveToken);
      localStorage.removeItem(llaveTipoUsuario);
      localStorage.removeItem(llaveIdUsuario);
    },
  },

  actions: {
    init({ commit }) {
      return new Promise((resolve) => {
        const token = localStorage.getItem(llaveToken);
        const tipoUsuarioStr = localStorage.getItem(llaveTipoUsuario);
        const idUsuarioStr = localStorage.getItem(llaveIdUsuario);

        if (token && tipoUsuarioStr && idUsuarioStr) {
          const tipoUsuario = parseInt(tipoUsuarioStr, 10);
          const idUsuario = parseInt(idUsuarioStr, 10);

          if (tipoUsuario === tiposUsuario.POSTULANTE) {
            validarTokenPostulante(idUsuario, token)
              .then((data) => {
                resolve(commit('loginPostulante', data));
              })
              .catch(() => {
                resolve(commit('logout'));
              });
          } else if (tipoUsuario === tiposUsuario.ESTUDIANTE) {
            validarTokenEstudianteAntiguo(idUsuario, token)
              .then((data) => {
                resolve(commit('loginEstudiante', data));
              })
              .catch(() => {
                resolve(commit('logout'));
              });
          } else if (tipoUsuario === tiposUsuario.EDUCONTINUA) {
            validarTokenEducacionContinua(idUsuario, token)
              .then((data) => {
                resolve(commit('loginPostulanteEduCont', data));
              })
              .catch(() => {
                resolve(commit('logout'));
              });
          }
        } else {
          resolve(commit('logout'));
        }
      });
    },
  },
});

export default store;
