
import { Component, Vue } from 'vue-property-decorator';
import { ModalidadCuotaI } from '@/assets/api/pagare';

const PropsInformacionPago = Vue.extend({
  props: {
    detallesPeriodo: {
      type: Object,
    },
    modo: {
      type: Number,
      default: 5,
    },
    modalidad: {
      type: Number,
      default: null,
    },
    modalidadesCuotas: {
      type: Array as () => ModalidadCuotaI[],
      default: () => [],
    },
    modalidadCuota: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});

@Component
export default class InformacionPago extends PropsInformacionPago {
  get descargaPagareCarrera() {
    const linkBase = 'https://drive.google.com/uc?export=download&id=';

    const modalidadCuota = this.modalidadesCuotas.filter(
      (modalidad) => modalidad.id === this.modalidadCuota,
    )[0];

    return `${linkBase}${modalidadCuota.link_pagare}`;
  }

  dia = (fecha: Date) => fecha.toLocaleString('es-cl', { day: '2-digit' });

  mes = (fecha: Date) => fecha.toLocaleString('es-cl', { month: 'long' });

  fecha(fecha: Date) {
    return `${this.dia(fecha)} de ${this.mes(fecha)}`;
  }
}
