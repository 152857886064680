import api from '@/assets/api';

const root = 'matricula';

interface PeriodoMatriculaAPI {
  id: number;
  proceso_matricula: number;
  descripcion: string;
  publico: number;
  fecha_inicio: string;
  fecha_fin: string;
}

interface PeriodoMatriculaI {
  id: number;
  proceso_matricula: number;
  descripcion: string;
  publico: number;
  fechaInicio: Date;
  fechaFin: Date;
}

function getPeriodosMatricula(publico: number): Promise<PeriodoMatriculaI[]> {
  return new Promise((resolve, reject) => {
    api.get(`${root}/periodos-matricula/`, { params: { publico } })
      .then((respuesta) => {
        resolve(
          respuesta.data.map((periodo: PeriodoMatriculaAPI) => ({
            ...periodo,
            fechaInicio: new Date(periodo.fecha_inicio),
            fechaFin: new Date(periodo.fecha_fin),
          })),
        );
      })
      .catch((error) => { reject(error.response.data); });
  });
}

interface PlanI {
  id: number;
  version: number;
  carrera: string;
  carrera_id: number;
  carrera_tipo: string;
  depto: string;
  link_decreto: string;
}

interface PostulanteI {
  id: number;
  persona: number;
  nombre: string;
  plan: PlanI;

  via_ingreso: string;
  resultado_postulacion: number;
  resultado_postulacion_display: string;
  posicion_lista_espera: number;

  preseleccionado_gratuidad: boolean;
  habilitado: boolean;
  acepta_vacante: boolean;

  estudiante: number | null;
  etapa_estudiante: number;
}

interface LoginPostulanteI {
  postulante: PostulanteI;
  accessToken: string;
}

function loginPostulante(data: {rut: string; numero_demre: string}): Promise<LoginPostulanteI> {
  return new Promise((resolve, reject) => {
    api.post(`${root}/login-postulantes/`, data, { headers: { Authorization: null } })
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

function validarTokenPostulante(postulante: number, token: string): Promise<LoginPostulanteI> {
  return new Promise((resolve, reject) => {
    api.post(`${root}/validar-token-postulantes/`, { postulante, token })
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

interface EstudianteI {
  id: number;
  persona: number;
  nombre: string;
  acepta_contrato: boolean;
  plan: PlanI;
  etapa_estudiante: number;
}

interface LoginEstudianteI {
  usuario: EstudianteI;
  accessToken: string;
}

function loginEstudianteAntiguo(data: { jwt: string }): Promise<LoginEstudianteI> {
  return new Promise((resolve, reject) => {
    // TODO: nueva ruta en backend
    api.post(`${root}/login-estudiantes-antiguos/`, data, { headers: { Authorization: null } })
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

function validarTokenEstudianteAntiguo(
  estudiante: number, token: string,
): Promise<LoginEstudianteI> {
  return new Promise((resolve, reject) => {
    api.post(`${root}/validar-token-estudiantes-antiguos/`, { estudiante, token })
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

function aceptarVacante(postulante: number): Promise<{ estudiante: number }> {
  return new Promise((resolve, reject) => {
    api.post(`${root}/aceptar-vacante/`, { postulante })
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

function aceptarContrato(): Promise<{mensaje: string}> {
  return new Promise((resolve, reject) => {
    api.post(`${root}/aceptar-contrato/`)
      .then((respuesta) => {
        resolve(respuesta.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
function comenzarProcesoEstudianteAntiguo(estudiante: number): Promise<{ estudiante: number }> {
  return new Promise((resolve, reject) => {
    api.post(`${root}/comenzar-proceso-estudiante-antiguo/`, { estudiante })
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

interface DatosContactoI {
  id: number;
  nombre_social: string;
  genero: string;

  mail_secundario: string;
  telefono_fijo: string;
  telefono_celular: string;

  nacionalidad: number | null;
  ciudad_origen: string;

  direccion_calle: string;
  direccion_numero: number;
  direccion_block: string;
  direccion_depto: string;
  direccion_villa: string;
  direccion_comuna: number | null;

  direccion_coyhaique: string;

  emergencia_nombre: string;
  emergencia_parentezco: string;
  emergencia_telefono_fijo: string;
  emergencia_telefono_laboral: string;
  emergencia_telefono_celular: string;
  emergencia_mail: string;

  educontinua_cargo: string;
  educontinua_empresa: string;
  educontinua_mail_laboral: string;

  autoriza_uso_imagen: boolean;
}

function getDatosContacto(estudiante: number): Promise<DatosContactoI> {
  return new Promise((resolve, reject) => {
    api.get(`${root}/datos-contacto/${estudiante}/`)
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

function patchDatosContacto(estudiante: number, data: DatosContactoI): Promise<DatosContactoI> {
  return new Promise((resolve, reject) => {
    api.patch(`${root}/datos-contacto/${estudiante}/`, data)
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

interface OrdenCompraI {
  id: number;
  persona: number;

  forma_pago: {
      id: number;
      nombre: string;
      modalidad_pago: number;
      modalidad_nombre: string;
  };
  monto_total: number;
  total_descuento_beneficios: number;

  estado_pago: number;
  estado_pago_display: string;
}

interface MatriculaEstudianteI {
  id: number;
  proceso_matricula: number;
  periodo_matricula: DetallesPeriodoAPI;
  estudiante: number;

  monto_final: number;
  tiene_beneficios: boolean;
  beneficios_monto_total: number;
  descripcion_beneficios: string;

  orden_compra: OrdenCompraI | null;
  estado: number;
  estado_display: string;
}

function getMatriculaEstudiante(estudiante: number): Promise<MatriculaEstudianteI> {
  return new Promise((resolve, reject) => {
    api.get(`${root}/matricula-estudiante/${estudiante}/`)
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

interface PagareI {
  id: number;
  arancel_estudiante: number;

  modalidad_cuota: number;
  fecha: string;

  monto_arancel: number;

  validado: boolean;
  estado: number;
  estado_display: string;
}

interface ArancelEstudianteI {
  id: number;
  proceso_matricula: number;
  periodo_matricula: DetallesPeriodoAPI;
  estudiante: number;

  monto: number;
  tiene_beneficios: boolean;
  beneficios_monto_total: number;
  descripcion_beneficios: string;

  pago_cuotas: boolean;
  orden_compra: OrdenCompraI | null;
  pagare: PagareI | null;
  estado_display: string;
}

function getArancelEstudiante(estudiante: number): Promise<ArancelEstudianteI> {
  return new Promise((resolve, reject) => {
    api.get(`${root}/arancel-estudiante/${estudiante}/`)
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

interface DetallesPeriodoAPI {
  dcto_oportuno: number;
  fecha_entrega_pagare: string;
  fecha_pago_matricula: string;
  fecha_pago_arancel: string;
}

interface DetallesPeriodoI {
  dctoOportuno: number;
  fechaEntregaPagare: Date;
  fechaPagoMatricula: Date;
  fechaPagoArancel: Date;
}

function parseDetallesPeriodo(detalles: DetallesPeriodoAPI): DetallesPeriodoI {
  return {
    dctoOportuno: detalles?.dcto_oportuno,
    fechaEntregaPagare: new Date(detalles?.fecha_entrega_pagare),
    fechaPagoMatricula: new Date(detalles?.fecha_pago_matricula),
    fechaPagoArancel: new Date(detalles?.fecha_pago_arancel),
  };
}

function getDetallesPeriodo(estudiante: number): Promise<DetallesPeriodoI | null> {
  return new Promise((resolve, reject) => {
    api.get(`${root}/detalles-periodo-actual/`, { params: { estudiante } })
      .then((respuesta) => {
        if (!respuesta.data) {
          resolve(null);
        }
        resolve(parseDetallesPeriodo(respuesta.data));
      })
      .catch((error) => { reject(error.response.data); });
  });
}

interface AceptarPagareI {
  estudiante: number;
  modalidad: number;
}

function postAceptarPagare(data: AceptarPagareI): Promise<{ OK: boolean }> {
  return new Promise((resolve, reject) => {
    api.post(`${root}/aceptar-pagare/`, data)
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

interface ResumenI {
    estudiante: number;
    matricula: MatriculaEstudianteI;
    arancel: ArancelEstudianteI;

    hay_periodo_abierto: boolean;
    habilitado: boolean;
}

function getResumen(estudiante: number): Promise<ResumenI> {
  return new Promise((resolve, reject) => {
    api.get(`${root}/resumen/${estudiante}`)
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

interface InhabilitantesMatriculaI {
  id: number;
  estudiante: number;
  proceso_matricula: number;

  tiene_deuda_finanzas: boolean;
  tiene_deuda_biblioteca: boolean;
}

function getInhabilitantesMatricula(estudiante: number): Promise<InhabilitantesMatriculaI> {
  return new Promise((resolve, reject) => {
    api.get(`${root}/inhabilitantes-matricula/${estudiante}/`)
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

interface PostulanteEduContI {
  id: number;
  persona: number;
  nombre: string;
  plan: PlanI;

  estudiante: number | null;
  etapa_estudiante: number;
}

interface LoginPostulanteEduContI {
  postulante: PostulanteEduContI;
  accessToken: string;
}

function loginEducacionContinua(
  data: {rut: string; clave: string},
): Promise<LoginPostulanteEduContI> {
  return new Promise((resolve, reject) => {
    api.post(`${root}/login-educacion-continua/`, data, { headers: { Authorization: null } })
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

function validarTokenEducacionContinua(
  postulante: number, token: string,
): Promise<LoginPostulanteEduContI> {
  return new Promise((resolve, reject) => {
    api.post(`${root}/validar-token-educacion-continua/`, { postulante, token })
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

function aceptarVacanteEducacionContinua(postulante: number): Promise<{ estudiante: number }> {
  return new Promise((resolve, reject) => {
    api.post(`${root}/aceptar-vacante-educacion-continua/`, { postulante })
      .then((respuesta) => { resolve(respuesta.data); })
      .catch((error) => { reject(error.response.data); });
  });
}

export {
  PlanI, PostulanteI, loginPostulante, validarTokenPostulante, aceptarVacante, DatosContactoI,
  getDatosContacto, patchDatosContacto, ArancelEstudianteI, getArancelEstudiante, ResumenI,
  getResumen, postAceptarPagare, EstudianteI, loginEstudianteAntiguo, validarTokenEstudianteAntiguo,
  getInhabilitantesMatricula, InhabilitantesMatriculaI, comenzarProcesoEstudianteAntiguo,
  PostulanteEduContI, loginEducacionContinua, validarTokenEducacionContinua, getPeriodosMatricula,
  PeriodoMatriculaI, aceptarVacanteEducacionContinua, getMatriculaEstudiante, MatriculaEstudianteI,
  DetallesPeriodoI, getDetallesPeriodo, parseDetallesPeriodo, aceptarContrato,
};
