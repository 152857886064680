// debe ser el primer import siempre
import '@/router/register-component-hooks';

import '@/assets/styles.css';
import '@/assets/fontawesome/css/all.min.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import Vue from 'vue';
import Multiselect from 'vue-multiselect';

import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

// registrar multiselect globalmente
Vue.component('multiselect', Multiselect);

store.dispatch('init')
  .then(() => {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  });
