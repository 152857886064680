import { render, staticRenderFns } from "./TabsInformacion.vue?vue&type=template&id=7530a297&scoped=true&"
import script from "./TabsInformacion.vue?vue&type=script&lang=ts&"
export * from "./TabsInformacion.vue?vue&type=script&lang=ts&"
import style0 from "./TabsInformacion.vue?vue&type=style&index=0&id=7530a297&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7530a297",
  null
  
)

export default component.exports