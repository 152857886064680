
import { Component, Vue } from 'vue-property-decorator';

import { loginEstudianteAntiguo } from '@/assets/api/matricula';

declare global {
  interface Window {
    handleCredentialResponse: (response: any) => void;
  }
}

@Component
export default class LoginAntiguos extends Vue {
  error = '';

  clientId = '';

  created() {
    this.clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;
  }

  mounted() {
    this.$emit('update:layout', 'div');
    const gsiClientScript = document.createElement('script');
    gsiClientScript.setAttribute('src', 'https://accounts.google.com/gsi/client');
    gsiClientScript.setAttribute('async', 'true');
    gsiClientScript.setAttribute('defer', 'defer');
    document.head.appendChild(gsiClientScript);

    window.handleCredentialResponse = (response) => {
      const jwt = response.credential;
      const codigo = { jwt };
      this.signInCallback(codigo);
    };
  }

  signInCallback(codigo: { jwt: string }) {
    loginEstudianteAntiguo(codigo)
      .then((estudiante) => {
        console.log(estudiante);
        this.$store.commit('loginEstudiante', estudiante);
        if (this.$store.getters.aceptaContrato) {
          if (this.$store.getters.etapaEstudiante === 1) {
            this.$router.replace({ name: 'perfil estudiante' });
          } else if (this.$store.getters.etapaEstudiante === 2) {
            this.$router.replace({ name: this.$store.getters.linkPagoMatricula });
          } else if (this.$store.getters.etapaEstudiante === 3) {
            this.$router.replace({ name: this.$store.getters.linkPagoArancel });
          } else if (this.$store.getters.etapaEstudiante === 4) {
            this.$router.replace({ name: 'resumen' });
          }
          // eslint-disable-next-line max-len
        } else if (this.$store.getters.etapaEstudiante === 0 && !(this.$store.getters.aceptaContrato)) {
          console.log('entro');
          this.$router.replace({ name: 'inhabilitantes antiguos' });
        } else {
          this.$router.replace({ name: 'contrato antiguos' });
        }
      })
      .catch((error) => {
        this.error = error.mensaje;
      });
  }
}
